import logo from './logo.svg';
import './App.css';

import React, { Component, useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, withRouter, BrowserRouter } from "react-router-dom";
import { Switch } from "react-router"
import { NavLink, Link, useNavigate } from "react-router-dom"

import Home from './components/pages/Home';
import AdjustmentReports from './components/pages/AdjustmentReports';
import AdjustmentReportsOS from './components/pages/AdjustmentReportsOS';
import AdjustmentReportsOSPending from './components/pages/AdjustmentReportsOSPending';
import ApproveReserveRelease from './components/pages/ApproveReserveRelease'
import ApproveReserveTotals from './components/pages/ApproveReserveTotals'
import CommissionReceivedReport from './components/pages/CommissionReceivedReport';
import CommissionReceivedReportOS from './components/pages/CommissionReceivedReportOS';
import CommissionReceivedReportOSPending from './components/pages/CommissionReceivedReportOSPending';
import Dashboard from './components/pages/Dashboard';
import DealerSearch from './components/pages/DealerSearch';
import ExpenseReporting from './components/pages/ExpenseReporting';
import ExpenseReportingOS from './components/pages/ExpenseReportingOS';
import ExpenseReportingPending from './components/pages/ExpenseReportingPending'
import ForgotPassword from './components/pages/ForgotPassword';
import Login from './components/pages/LogIn';
import ManageDealers from './components/pages/ManageDealers';
import ManageMGMT from './components/pages/ManageMGMT';
import ManageOfficeStaff from './components/pages/ManageOfficeStaff';
import ManageSpiffees from './components/pages/ManageSpiffees';
import ManageSubagents from './components/pages/ManageSubagents';
import ManageAdmins from './components/pages/ManageAdmins'
import Permissions from './components/pages/Permissions';
import PreSpiffReporting from './components/pages/PreSpiffReporting';
import PreSpiffReportingPending from './components/pages/PreSpiffReportingPending'
import PreSpiffReportingOS from './components/pages/PreSpiffReportingOS'
import ResetPassword from './components/pages/ResetPassword';
import SaturdayReporting from './components/pages/SaturdayReporting';
import SaturdayReportingApprove from './components/pages/SaturdayReportingApprove';
import SaturdayReportingPending from './components/pages/SaturdayReportingPending';
import SaturdayReportingOS from './components/pages/SaturdayReportingOS';
import SpiffsGreaterThan50 from './components/pages/SpiffsGreaterThan50';
import ThursdayReporting from './components/pages/ThursdayReporting';
import ThursdayReportingOS from './components/pages/ThursdayReportingOS';
import ThursdayReportingApprove from './components/pages/ThursdayReportingApprove';
import ThursdayReportingPending from './components/pages/ThursdayReportingPending';
import UserSettings from './components/pages/UserSettings';
import ManagePrespiffs from './components/pages/ManagePrespiffs'

import CombinedDealerReport from './components/outputs/CombinedDealerReport'
import CombinedSubagentReport from './components/outputs/CombinedSubagentReport'
import NewCombinedSubagentReport from './components/outputs/NewCombinedSubagentReport'
import ContractCountReport from './components/outputs/ContractCountReport'
import InsideSalesReport from './components/outputs/InsideSalesReport'
import ListOfGiftCards from './components/outputs/ListOfGiftCards'
import ListOfReserveInputs from './components/outputs/ListOfReserveInputs'
import ListOfReserveOutputs from './components/outputs/ListOfReserveOutputs'
import ListOfSaturdayReports from './components/outputs/ListOfSaturdayReports'
import NewDealerSignups from './components/outputs/NewDealerSignups'
import OutstandingPrespiffs from './components/outputs/OutstandingPrespiffs'
import RecentAddressChanges from './components/outputs/RecentAddressChanges'
import ReportingHistory from './components/outputs/ReportingHistory'
import ReserveAccountReports from './components/outputs/ReserveAccountReports'
import SpiffeesReport from './components/outputs/SpiffeesReport'
import SubagentBusiness from './components/outputs/SubagentBusiness'
import SubagentNetAndInsideSales from './components/outputs/SubagentNetAndInsideSales'
import SubagentWeeklyChecks from './components/outputs/SubagentWeeklyChecks'
import VoidAndNeedsReplaced from './components/outputs/VoidAndNeedsReplaced'
import MetricsHome from './components/outputs/MetricsHome'
import ReserveAccountWeeks from './components/outputs/ReserveAccountWeeksList'
import CombinedSpiffeesReport from './components/outputs/CombinedSpiffeeReport'
import ListOfExpenseReports from './components/outputs/ListOfExpenseReports'
import ListOfAdjustmentReports from './components/outputs/ListOfAdjustmentReports'
import ListOfDPPPGAP from './components/outputs/ListOfDPPPGAP'
import ListOfReserveAccountTotals from './components/outputs/ListOfReserveAccountTotals'
import ReserveAccounts from './components/pages/ManageReserveAccounts'
import ReserveRelease from './components/pages/ReserveRelease'
import LateReporting from './components/pages/LateReporting'
import ThursdayReportingDenied from './components/pages/ThursdayReportingDenied'
import SaturdayReportingDenied from './components/pages/SaturdayReportingDenied'
import CommissionReceivedReportDenied from './components/pages/CommissionReceivedReportOSDenied'
import PreSpiffReportingDenied from './components/pages/PreSpiffReportingDenied'
import PrespiffPaidOff from './components/outputs/PrespiffPaidOff'
import DealerTotals from './components/outputs/DealerTotals'

import AuthWrapper from './components/elements/AuthWrapper'

import { CookiesProvider, useCookies, withCookies } from 'react-cookie';

function App() {

  const colors = {
    backgroundColor: '#F2F6F8',
    primary: '#00A4E6',
    block: 'white',
    font: 'black',
    muted: '#989898',
    fontInvert: 'white',
    danger: '#d1c20a',
    warning: '#FF3E3E',
    success: '',
    border: '#D7D7D7',
    highlight: '#ebebeb',
  }

  const darkcolors = {
    backgroundColor: 'grey',
  }

  const getServerResponse = (json) => {
    console.log(json)
    if (typeof json.detail === 'string' || json.detail instanceof String) {
      return (getCurrentTimeFormatted() + ': ' + json.detail)
    } else {
      return json.detail.map((error, index) => {
        return (getCurrentTimeFormatted() + ': ' + error.msg + ' at ' + error.loc.join(', ')).replace(/string/g, "text");
      })
    }
  }
  
  // const baseURL = 'https://cba-api-prod-v1.thinetics.io'
  const baseURL = 'https://cberman-api-dev.thinetics.io'

  const versionNumber = '1.0.7.3';
  //hmmm

  useEffect(() => {
    document.title = baseURL === 'https://cberman-api-dev.thinetics.io' ? 'DEVELOPMENT - ' + versionNumber:'CBA Portal V' + versionNumber;
  }, []);

  function getCurrentTimeFormatted() {
    const now = new Date();

    let hours = now.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12; // Convert to 12-hour format
    hours = hours ? hours : 12; // The hour '0' should be '12'

    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
  }

  const [cookies, setCookie] = useCookies([])

  // Mark the function as async to use await inside it
  const getNewAccessToken = async (refresh) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + refresh); 

    var requestOptions2 = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    try {
      // Use await to wait for the fetch operation to complete
      const response = await fetch(baseURL + '/auth/refresh', requestOptions2);
      const result = await response.json(); // Wait for the JSON conversion

      if (result.access_token === undefined) {
        // Handle bad refresh token
        window.location.assign('/login');
        localStorage.clear()
        return null; // Explicitly return null or some indication of failure
      }

      console.log(result.access_token);
      return result.access_token; // Return the token
    } catch (error) {
      console.error("Error fetching access token:", error);
      // Handle errors, such as network issues
      return null; // Return null or some indication of failure
    }
  };

  const tokenSafeAPIRequest = (endpoint, requestOptions, tokenValues, operation) => {
    let accessToken = localStorage.getItem('access_token')
    let refreshToken = localStorage.getItem('refresh_token')
    let responseFromServer = ''
    if (accessToken === undefined)
    {
      if (refreshToken !== undefined)
      {
        //get new access token
        let newAccessToken = getNewAccessToken(refreshToken)
        localStorage.setItem('access_token', newAccessToken)
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + newAccessToken); 
        fetch(baseURL + endpoint, requestOptions)
        .then(response => {responseFromServer = response.status; return response.json()})
        .then(result => {
          //runs original code
          operation(result, responseFromServer)
        })
      }
      else
      {
        //no tokens, you are not suppose to be here
        localStorage.clear()
        window.location.assign('/login');
      }
    }
    else
    {
      //has access token, proceed
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", "Bearer " + refreshToken); 
      requestOptions.headers.set('Authorization', "Bearer " + accessToken)
      fetch(baseURL + endpoint, requestOptions)
      .then(response => {responseFromServer = response.status; return response.json()})
      .then(result => {
        //runs original code
        if (result.detail === 'Token has expired. Please re-login.')
        {
          if (localStorage.getItem('refresh_token') === undefined)
          {
            localStorage.clear()
            window.location.assign('/login');
          }
          else
          {
            let newAccessToken = '';
            getNewAccessToken(refreshToken).then((returnedAccessToken) => {
              console.log("New access token:", returnedAccessToken);
              newAccessToken = returnedAccessToken
              console.log(newAccessToken)
              localStorage.setItem('access_token', newAccessToken)
              var myHeaders = new Headers();
              myHeaders.append("Accept", "application/json");
              myHeaders.append("Authorization", "Bearer " + newAccessToken); 
              fetch(baseURL + endpoint, requestOptions)
              .then(response => {responseFromServer = response.status; return response.json()})
              .then(result => {
                //runs original code
                operation(result, responseFromServer)
                window.location.reload()
              })
            });
          }
        }
        else
        {
          operation(result, responseFromServer)
        }
      })
    }
  }

  return (
    <CookiesProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' exact element={<AuthWrapper><Home colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>

          {/* Reports */}
          <Route path='/reports/adjustmentreporting' exact element={<AuthWrapper><AdjustmentReports colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/adjustmentreportingOS' exact element={<AuthWrapper><AdjustmentReportsOS colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/adjustmentreportingOSPending' exact element={<AuthWrapper><AdjustmentReportsOSPending colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/commissionReceived' exact element={<AuthWrapper><CommissionReceivedReport colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/commissionReceivedOS' exact element={<AuthWrapper><CommissionReceivedReportOS colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/commissionReceivedOSpending' exact element={<AuthWrapper><CommissionReceivedReportOSPending colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/prespiffReporting' exact element={<AuthWrapper><PreSpiffReporting colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/prespiffReportingPending' exact element={<AuthWrapper><PreSpiffReportingPending colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/prespiffReportingOS' exact element={<AuthWrapper><PreSpiffReportingOS colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/saturdayReporting' exact element={<AuthWrapper><SaturdayReporting colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/saturdayReportingApprove' exact element={<AuthWrapper><SaturdayReportingApprove colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/saturdayReportingPending' exact element={<AuthWrapper><SaturdayReportingPending colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/saturdayReportingOS' exact element={<AuthWrapper><SaturdayReportingOS colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/thursdayReporting' exact element={<AuthWrapper><ThursdayReporting colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/thursdayReportingapprove' exact element={<AuthWrapper><ThursdayReportingApprove colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/thursdayReportingpending' exact element={<AuthWrapper><ThursdayReportingPending colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/thursdayReportingOS' exact element={<AuthWrapper><ThursdayReportingOS colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/spiffsGreaterThan50' exact element={<AuthWrapper><SpiffsGreaterThan50 colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/expense' exact element={<AuthWrapper><ExpenseReporting colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/expensepending' exact element={<AuthWrapper><ExpenseReportingPending colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/expenseOS' exact element={<AuthWrapper><ExpenseReportingOS colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/reservereleaserequests' exact element={<AuthWrapper><ApproveReserveRelease colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/reservetotals' exact element={<AuthWrapper><ApproveReserveTotals colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/reserveRelease' exact element={<AuthWrapper><ReserveRelease colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/latereporting' exact element={<AuthWrapper><LateReporting colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/thursdayReportingdenied' exact element={<AuthWrapper><ThursdayReportingDenied colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/saturdayReportingDenied' exact element={<AuthWrapper><SaturdayReportingDenied colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/commissionReceivedOSDenied' exact element={<AuthWrapper><CommissionReceivedReportDenied colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/reports/prespiffReportingDenied' exact element={<AuthWrapper><PreSpiffReportingDenied colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>

          {/* Manage */}
          <Route path='/manage/dealers' exact element={<AuthWrapper><ManageDealers colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/manage/MGMT' exact element={<AuthWrapper><ManageMGMT colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/manage/OS' exact element={<AuthWrapper><ManageOfficeStaff colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/manage/spiffees' exact element={<AuthWrapper><ManageSpiffees colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/manage/subagents' exact element={<AuthWrapper><ManageSubagents colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/manage/admins' exact element={<AuthWrapper><ManageAdmins colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/manage/reserveAccounts' exact element={<AuthWrapper><ReserveAccounts colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/manage/prespiffs' exact element={<AuthWrapper><ManagePrespiffs colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>

          {/* Authentication */}
          <Route path='/login' exact element={<Login colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/>}>

          </Route>
          <Route path='/resetPassword/:key' element={<ResetPassword colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/>}>

          </Route>
          <Route path='/forgotPassword' exact element={<ForgotPassword colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/>}>

          </Route>

          {/* General */}
          <Route path='/dashboard' exact element={<AuthWrapper><Dashboard colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/dealerSearch' exact element={<AuthWrapper><DealerSearch colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/settings' exact element={<AuthWrapper><UserSettings colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/permissions' exact element={<AuthWrapper><Permissions colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>

          {/* Outputs */}
          <Route path='/output' exact element={<AuthWrapper><MetricsHome colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/combinedDealerReport' exact element={<AuthWrapper><CombinedDealerReport colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/combinedSubagentReport' exact element={<AuthWrapper><CombinedSubagentReport colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/combinedSpiffeesReport' exact element={<AuthWrapper><CombinedSpiffeesReport colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/newcombinedSubagentReport' exact element={<AuthWrapper><NewCombinedSubagentReport colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/contractCountReport' exact element={<AuthWrapper><ContractCountReport colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/insideSalesReport' exact element={<AuthWrapper><InsideSalesReport colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/listOfGiftCards' exact element={<AuthWrapper><ListOfGiftCards colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/listOfReserveInputs' exact element={<AuthWrapper><ListOfReserveInputs colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/listOfReserveOutputs' exact element={<AuthWrapper><ListOfReserveOutputs colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/listOfSaturdayReports' exact element={<AuthWrapper><ListOfSaturdayReports colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/newDealerSignups' exact element={<AuthWrapper><NewDealerSignups colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/outstandingPrespiffs' exact element={<AuthWrapper><OutstandingPrespiffs colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/recentAddressChanges' exact element={<AuthWrapper><RecentAddressChanges colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/reserveAccountWeeks' exact element={<AuthWrapper><ReserveAccountWeeks colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>  
          <Route path='/output/reportingHistory' exact element={<AuthWrapper><ReportingHistory colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/reserveAccountReports' exact element={<AuthWrapper><ReserveAccountReports colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/spiffeesReport' exact element={<AuthWrapper><SpiffeesReport colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/subagentBusiness' exact element={<AuthWrapper><SubagentBusiness colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/subagentNetAndInsideSales' exact element={<AuthWrapper><SubagentNetAndInsideSales colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/subagentWeeklyChecks' exact element={<AuthWrapper><SubagentWeeklyChecks colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/voidAndNeedsReplaced' exact element={<AuthWrapper><VoidAndNeedsReplaced colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/expenseReports' exact element={<AuthWrapper><ListOfExpenseReports colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/adjustmentReports' exact element={<AuthWrapper><ListOfAdjustmentReports colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/DPPPGAP' exact element={<AuthWrapper><ListOfDPPPGAP colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/reserveAccountTotals' exact element={<AuthWrapper><ListOfReserveAccountTotals colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/prespiffPaidOff' exact element={<AuthWrapper><PrespiffPaidOff colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
          <Route path='/output/dealerTotals' exact element={<AuthWrapper><DealerTotals colors={colors} getServerResponse={getServerResponse} baseURL={baseURL} tokenSafeAPIRequest={tokenSafeAPIRequest}/></AuthWrapper>}>

          </Route>
        </Routes>
      </BrowserRouter>
    </CookiesProvider>
    
  );
}

export default App;